import React from "react";

import "./hero-card.scss";
import { STORAGE_KEY } from "../../../constants";

const HeroCard = () => {
  const routeToCohortAndCache = () => {
    global.window.localStorage.setItem(STORAGE_KEY, "true");
  };
  return (
    <div className="hero-section">
      <div className="hero-card ae-4">
        <img src="/assets/img/hero-pg.svg" />
        <h4 className="largest">
          Your journey to product design mastery starts here.{" "}
          <span>Cohort 1</span>
        </h4>

        <p>
          <strong>
            We’re shaping the future of next-gen product designers.
          </strong>{" "}
          Our immersive 3-month coaching program will unlock your creative
          potential and set you on the path to a thriving design career.{" "}
          <a href="/cohort">Learn more</a>
        </p>
        <ul>
          <li>
            <span>Next Cohort: </span>
            <strong>March 15, 2025</strong>
          </li>
          <li>
            <span>Seats: </span>
            <strong>10 Seats</strong>
          </li>
          <li>
            <span>Price: </span>
            <strong>$320</strong>
          </li>
          <li>
            <span>Level: </span>
            <strong>Zero experience, Newbies, Juniors.</strong>
          </li>
        </ul>
        <button>
          <a onClick={routeToCohortAndCache} href="/cohort">
            Enroll Now
          </a>
        </button>
      </div>
    </div>
  );
};

export default HeroCard;
