import React from "react";
import Layout from "../layout";
import SEO from "../seo";

import KoraLogo from "../reusables/company-logos/KoraLogo";
import QuidaxLogo from "../reusables/company-logos/QuidaxLogo";
// import PiggyvestLogo from "../reusables/company-logos/PiggyvestLogo";
import FincraLogo from "../reusables/company-logos/FincraLogo";
import DandeLogo from "../reusables/company-logos/DandeLogo";

import HeroCard from "./hero-card/HeroCard";
import "./home-comp.scss";

const HomeComp = () => {
  return (
    <Layout light={false}>
      <SEO
        title={"Welcome"}
        description={"The meeting place for people, products, and great design"}
      />
      <section className="slide kenBurns home-comp">
        <div className="content">
          <div className="container">
            <div className="home-comp">
              <div className="hero-title">
                <div>
                  <div className="fix-10x-12">
                    <h1 className="ae-1 largest">
                      Product design education for everyone.
                    </h1>
                    <p className="ae-2 largest">
                      Upgrade your design skills. Advance your professional
                      career in UI, UX and Product Design.
                    </p>
                  </div>
                  {/* <a
                    className="button rounded margin-top-1 ae-5 fromCenter"
                    href="/learn"
                  >
                    Start Learning
                  </a>
                  <a
                    className="button transparent rounded margin-top-1 ae-6 fromCenter"
                    href="/join_unflux"
                  >
                    <svg>
                      <use
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        xlinkHref="/assets/svg/icons.svg#flat-star-icon"
                      ></use>
                    </svg>
                    Become a member
                  </a> */}
                </div>
              </div>
              <HeroCard />
              <div className="hero-footer">
                <small>Coaches and Partners from Top Companies</small>
                <div className="logo-group">
                  <KoraLogo fill="currentColor" />
                  <QuidaxLogo fill="currentColor" />
                  {/* <PiggyvestLogo fill="currentColor" /> */}
                  <FincraLogo fill="currentColor" />
                  <DandeLogo fill="currentColor" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="background"
          style={{ backgroundImage: "url(/assets/img/background/img-11.jpg)" }}
        ></div>
      </section>
    </Layout>
  );
};

export default HomeComp;
